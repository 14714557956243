import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import Agreement from '../interfaces/output/agreement';
import AgreementFilters from '../interfaces/filters/agreementFilters';
import AgreementVersion from '../interfaces/output/agreementVersion';
import { ContentTypeConstants } from '../constants/browserConstants';
import moment from 'moment';
import SortFormatterUtils from '../utils/sortFormatterUtils';
//

export default class AgreementApi {
    static fetchAgreementById = async(agreementId: number, agreementVersionId?: number): Promise<Agreement> => {
        const versionQueryString = agreementVersionId ? { versionId: agreementVersionId } : {};
        const url = urlcat('', `agreement/${agreementId}`, versionQueryString);
        const { data } = await http.get<Agreement>(url);

        if(data.version === null || data.version === undefined || data.version.id === null || data.version.id === undefined) {
            if(data.latestActiveVersion === null || data.latestActiveVersion === undefined || data.latestActiveVersion.id === null || data.latestActiveVersion.id === undefined) {
                data.version = data.inProgressVersion;
            }
            else {
                data.version = data.latestActiveVersion;
            }
        }

        return data;
    };

    static fetchAgreements = async(paging: Paging, filters: AgreementFilters): Promise<Array<Agreement>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/agreement/search');

        const { data } = await http.get<Array<Agreement>>(url);

        return data;
    };

    static fetchAgreementVersions = async(agreementId: number, paging: Paging): Promise<Array<AgreementVersion>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/agreement/${agreementId}/versions`);
        const { data } = await http.get<Array<Agreement>>(url);

        return data;
    };

    static createAgreement = async(agreement: Agreement): Promise<Agreement> => {
        const newAgreement = {
            name: agreement.name,
            active: true,
            sellerId: agreement.seller?.id,
            unitGroupId: agreement.unitGroup?.id,
            version: agreement.version,
            currencyId: agreement.currency?.id
        };

        const { data } = await http.post<any, Agreement>('agreement', newAgreement);

        return data;
    }

    static createAgreementVersion = async(agreement: Agreement): Promise<AgreementVersion> => {
        const url = `/agreement/${agreement.id}/versions`;
        const agreementVersion = agreement.version;

        const newAgreementVersion = {
            validFromDate: agreementVersion?.validFromDate,
            validToDate: agreementVersion?.validToDate,
            statusId: 1, //set status to in progress
            defaultTransitDiscount: agreementVersion?.defaultTransitDiscount,
            version: agreementVersion?.versionNumber
        };

        const { data } = await http.post<any, AgreementVersion>(url, newAgreementVersion);

        return data;
    }

    static updateAgreement = async(agreement: Agreement): Promise<Agreement> => {
        const newAgreement = {
            name: agreement.name,
            active: true,
            sellerId: agreement.seller?.id,
            unitGroupId: agreement.unitGroup?.id,
            version: agreement.version,
            currencyId: agreement.currency?.id
        };

        const { data } = await http.put<any, Agreement>(`agreement/${agreement.id}`, newAgreement);

        return data;
    }

    static deactivateAgreement = async(agreement: Agreement): Promise<Agreement> => {
        const newAgreement = {
            name: agreement.name,
            active: false,
            sellerId: agreement.seller?.id,
            unitGroupId: agreement.unitGroup?.id,
            version: agreement.version
        };

        const { data } = await http.put<any, Agreement>(`agreement/${agreement.id}`, newAgreement);

        return data;
    }

    static exportAgreement = async(id: number, fromDate: Date): Promise<Blob> => {
        const date = fromDate ? moment(fromDate).toISOString() : undefined;
        const url = urlcat('', `/agreement/${id}/pdf`, { date });
        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.pdf);

        return data;
    };
}