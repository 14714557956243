import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../common/widgets/genericStepper';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStep from '../../interfaces/common/genericStep';
import { FormType } from '../../constants/constants';
import AddAgreementVersionPricelistStep3 from './addAgreementVersionPricelistStep3';
import {
    createAgreementVersionPricelists, fetchAgreementVersionPricelistsById, getAgreementVersionPriceList, updateAgreementVersionPricelist, isCreatingAgreementPricelist,
    isUpdatingAgreementPricelist,
    isLoadingAgreementVersionPriceList
} from '../../reducers/agreementVersionPriceListReducer';
import AgreementVersionPricelist from '../../interfaces/output/agreementVersionPricelist';
import AddAgreementVersionPricelistStep2 from './addAgreementVersionPricelistStep2';
import UrlConstants from '../../constants/UrlConstants';
import AddAgreementVersionStep3 from '../agreements/agreementVersion/addAgreementVersionStep3';
import { ensure } from '../../utils/arrayUtils';
import AddAgreementVersionStep2 from '../agreements/agreementVersion/addAgreementVersionStep2';
import AddAgreementStep2Validation from './validations/addAgreementStep2Validation';
import { fetchAgreementById, getAgreement, isLoadingAgreement } from '../../reducers/agreementsReducer';
import Agreement from '../../interfaces/output/agreement';
import StepperAdditionlInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddAgreementVersionPricelistProps {
    priceListId?: number;
    agreementId?: number;
    agreementVersionPricelistId?: number;
    priceListVersionId?: number;
    agreementVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit agreement version price list'),
    add: LanguageUtils.createMessage('Add agreement version price list'),
    editUnit: LanguageUtils.createMessage('Edit order unit'),
    addUnit: LanguageUtils.createMessage('Add order unit')
};

export default function AddAgreementVersionPricelistForm(props: IAddAgreementVersionPricelistProps): JSX.Element {
    const { priceListId, steps, type, agreementId, agreementVersionPricelistId, agreementVersionId, priceListVersionId } = props;
    const dispatch = useDispatch();
    const [agreementVersionPricelist, setAgreementVersionPricelist] = React.useState<AgreementVersionPricelist>({
        agreement: {
            id: agreementId,
            version: { id: agreementVersionId }
        },
        pricelist: {
            id: priceListId,
            version: { id: priceListVersionId }
        }
    } as AgreementVersionPricelist);
    const [agreement, setAgreement] = React.useState<Agreement>();
    const propsAgreementVersionPricelist = useSelector(getAgreementVersionPriceList);
    const propsAgreement = useSelector(getAgreement);
    //const prevAgreementVersionPricelist = WorkflowUtils.usePrevious<AgreementVersionPricelist>(propsAgreementVersionPricelist);
    const propsIsUpdatingAgreementVersionPricelist = useSelector(isUpdatingAgreementPricelist);
    const prevIsUpdatingAgreementVersionPricelist = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingAgreementVersionPricelist);
    const propsIsCreatingAgreementVersionPricelist = useSelector(isCreatingAgreementPricelist);
    const prevIsCreatingAgreementVersionPricelist = WorkflowUtils.usePrevious<boolean>(propsIsCreatingAgreementVersionPricelist);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [selectedAgreementVersionId, setSelectedAgreementVersionId] = React.useState<number | undefined>();
    const propsIsLoadingAgreementVersionPriceList = useSelector(isLoadingAgreementVersionPriceList);
    const prevIsLoadingAgreementVersionPriceList = WorkflowUtils.usePrevious<boolean>(propsIsLoadingAgreementVersionPriceList);
    const propsIsLoadingAgreement = useSelector(isLoadingAgreement);
    const prevIsLoadingAgreement = WorkflowUtils.usePrevious<boolean>(propsIsLoadingAgreement);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 4:
                if(prevIsCreatingAgreementVersionPricelist === true && !propsIsCreatingAgreementVersionPricelist ||
                    prevIsUpdatingAgreementVersionPricelist === true && !propsIsUpdatingAgreementVersionPricelist) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingAgreementVersionPricelist, propsIsCreatingAgreementVersionPricelist]);

    useEffect(() => {
        if(agreementVersionPricelistId) {
            dispatch(fetchAgreementVersionPricelistsById(agreementVersionPricelistId));
        }
        if(agreementId) {
            dispatch(fetchAgreementById({ id: agreementId }));
        }
    }, [dispatch]);

    // useEffect(() => {
    //     if(propsAgreementVersionPricelist.id && prevAgreementVersionPricelist) {
    //         setAgreementVersionPricelist(propsAgreementVersionPricelist);
    //     }

    // }, [propsIsUpdatingAgreementVersionPricelist, propsAgreementVersionPricelist.id]);

    useEffect(() => {
        if(!propsIsLoadingAgreementVersionPriceList && prevIsLoadingAgreementVersionPriceList) {
            setAgreementVersionPricelist(propsAgreementVersionPricelist);
        }

    }, [propsIsLoadingAgreementVersionPriceList]);

    useEffect(() => {
        if(!propsIsLoadingAgreement && prevIsLoadingAgreement) {
            setAgreement(propsAgreement);
        }

    }, [propsIsLoadingAgreement]);

    const onChange = (attribute: string, value: any) => {
        const newAgreementVersion = { ...agreementVersionPricelist } as AgreementVersionPricelist;
        (newAgreementVersion as any)[attribute] = value;

        setAgreementVersionPricelist(newAgreementVersion);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            name: 'Select agreement from list',
            xl: true,
            validationFn: () => AddAgreementStep2Validation.validateAddAgreementForm(agreementVersionPricelist.agreement?.id),
            content: <AddAgreementVersionStep2 agreementId={agreementVersionPricelist.agreement?.id}
                onChange={(agreementId: number | undefined) => setAgreementVersionPricelist({
                    ...agreementVersionPricelist,
                    agreement: {
                        ...agreementVersionPricelist.agreement,
                        id: agreementId
                    }
                })} />
        } as GenericStep,
        {
            id: 2,
            name: 'Select pricelist from list',
            xl: true,
            content: <AddAgreementVersionPricelistStep2 agreementVersionPricelist={agreementVersionPricelist} pricelistId={priceListId} priceListVersionId={priceListVersionId}
                onChange={setAgreementVersionPricelist} agreement={agreement} />
        } as GenericStep,
        {
            id: 3,
            content: <AddAgreementVersionStep3 agreementId={agreementVersionPricelist.agreement?.id} selectedAgreementVersionId={selectedAgreementVersionId} onChange={setSelectedAgreementVersionId}/>,
            name: 'Select agreement version from list',
            onNext: () => {
                if(selectedAgreementVersionId) {
                    setAgreementVersionPricelist({
                        ...agreementVersionPricelist,
                        agreement: {
                            ...agreementVersionPricelist.agreement,
                            version: {
                                ...agreementVersionPricelist.agreement?.version,
                                id: ensure(selectedAgreementVersionId)
                            }
                        }
                    });
                }
            },
            xl: true
        } as GenericStep,
        {
            id: 4,
            content: <AddAgreementVersionPricelistStep3 agreementVersionPricelist={agreementVersionPricelist} onChange={onChange} />,
            name: 'Add discount',
            onNext: () => {
                if(!agreementVersionPricelist.id) {
                    dispatch(createAgreementVersionPricelists(agreementVersionPricelist));
                }
                else {
                    dispatch(updateAgreementVersionPricelist(agreementVersionPricelist));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.EditAgreementVersionPriceListViaPriceList:
                return messages.edit;
            case FormType.EditAgreementVersionPriceListViaAgreement:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): any => {
        switch (type) {
            case FormType.Add:
                if(agreementId) {
                    return `/${UrlConstants.AGREEMENTS}/${agreementId}/${agreementVersionId}/pricelist`;
                }

                return propsAgreementVersionPricelist.pricelist?.version?.id ?
                    `/${UrlConstants.PRICE_LISTS}/${priceListId}/${propsAgreementVersionPricelist.pricelist?.version?.id}/agreements` :
                    `/${UrlConstants.PRICE_LISTS}/${priceListId}/latest/agreements`;

            case FormType.EditAgreementVersionPriceListViaPriceList:
                return propsAgreementVersionPricelist.pricelist?.version?.id ?
                    `/${UrlConstants.PRICE_LISTS}/${propsAgreementVersionPricelist.pricelist?.id}/${propsAgreementVersionPricelist.pricelist?.version?.id}/agreements` :
                    `/${UrlConstants.PRICE_LISTS}/${propsAgreementVersionPricelist.pricelist?.id}/latest/agreements`;

            case FormType.EditAgreementVersionPriceListViaAgreement:
                return propsAgreementVersionPricelist.agreement?.version?.id ?
                    `/${UrlConstants.AGREEMENTS}/${propsAgreementVersionPricelist.agreement?.id}/${propsAgreementVersionPricelist.agreement?.version?.id}/pricelist` :
                    `/${UrlConstants.AGREEMENTS}/${propsAgreementVersionPricelist.agreement?.id}/latest/pricelist`;

            default:
                throw new Error('Form type required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
            additionalDetails={<StepperAdditionlInformation agreement={agreement} priceList={agreementVersionPricelist?.pricelist} />}
        />
    );
}
