import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { FormType } from '../../constants/constants';
import Agreement from '../../interfaces/output/agreement';
import { createAgreement, createAgreementVersion, fetchAgreementById, getAgreement, isCreating, isCreatingVersion, isUpdatingAgreement, updateAgreement } from '../../reducers/agreementsReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import AddAgreementStep1 from './add/addAgreementStep1';
import AddAgreementStep2 from './add/addAgreementStep2';
import AgreementVersion from '../../interfaces/output/agreementVersion';
import { cloneAgreementVersion, isCloningVersion, isUpdatingAgreementStatus, isUpdatingVersion, updateAgreementVersion, updateAgreementVersionStatus } from '../../reducers/agreementVersionReducer';
import AddAgreementVersionStep2 from './agreementVersion/addAgreementVersionStep2';
import AddAgreementVersionStep3 from './agreementVersion/addAgreementVersionStep3';
import AddAgreementStep3 from './add/addAgreementStep3';
import { ensure } from '../../utils/arrayUtils';
import AgreementStep1Validation from './validations/agreementStep1Validation';
import AgreementStep2Validation from './validations/agreementStep2Validation';
import AddAgreementVersionStep4 from './agreementVersion/addAgreementVersionStep4';
import AgreementStep3Validation from './validations/agreementStep3Validation';
import StepperAdditionlInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddAgreementProps {
    agreementId?: number;
    agreementVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit agreement'),
    add: LanguageUtils.createMessage('Add agreement'),
    editVersion: LanguageUtils.createMessage('Edit agreement version'),
    addVersion: LanguageUtils.createMessage('Add agreement version'),
    changeStatus: LanguageUtils.createMessage('Change agreement status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone'),
    chooseVersion: LanguageUtils.createMessage('Choose version')
};

export default function AddAgreementForm(props: IAddAgreementProps): JSX.Element {
    const dispatch = useDispatch();
    const { agreementId, steps, type, agreementVersionId } = props;
    const [agreement, setAgreement] = React.useState<Agreement>({ id: agreementId,
        version: {} } as Agreement);
    const [selectedAgreementId, setSelectedAgreementId] = React.useState<number | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsAgreement = useSelector(getAgreement);
    const prevAgreement = WorkflowUtils.usePrevious<Agreement>(propsAgreement);
    const propsIsUpdatingAgreement = useSelector(isUpdatingAgreement);
    const prevIsUpdatingAgreement = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingAgreement);
    const propsIsCreatingAgreement = useSelector(isCreating);
    const prevIsCreatingAgreement = WorkflowUtils.usePrevious<boolean>(propsIsCreatingAgreement);
    const propsIsCreatingAgreementVersion = useSelector(isCreatingVersion);
    const prevIsCreatingAgreementVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingAgreementVersion);
    const propsIsUpdatingAgreementVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingAgreementVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingAgreementVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingAgreementStatus = useSelector(isUpdatingAgreementStatus);
    const prevIsUpdatingAgreementStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingAgreementStatus);
    const [selectedAgreementVersionId, setSelectedAgreementVersionId] = React.useState<number | undefined>();

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsCreatingAgreement === true && !propsIsCreatingAgreement || prevIsUpdatingAgreement === true && !propsIsUpdatingAgreement) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingAgreementVersion === true && !propsIsCreatingAgreementVersion || prevIsUpdatingAgreementVersion === true && !propsIsUpdatingAgreementVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 6:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 5:
                if(prevIsUpdatingAgreementStatus === true && !propsIsUpdatingAgreementStatus) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingAgreement, propsIsCreatingAgreementVersion, propsIsCreatingAgreement, propsIsUpdatingAgreementVersion, propsIsCloningVersion, propsIsUpdatingAgreementStatus]);

    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && agreementId) {
            dispatch(fetchAgreementById({ id: agreementId,
                agreementVersionId }));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(prevAgreement && propsAgreement.id) {
            setAgreement(propsAgreement);
        }

    }, [propsAgreement.id]);
    
    const onChange = (attribute: string, value: any) => {
        const newAgreement = { ...agreement } as Agreement;
        (newAgreement as any)[attribute] = value;

        setAgreement(newAgreement);
    };

    const onChangeVersion = (attribute: string, value: any) => {
        const newAgreementVersion = { ...agreement.version } as AgreementVersion;
        (newAgreementVersion as any)[attribute] = value;

        setAgreement({
            ...agreement,
            version: newAgreementVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddAgreementStep1 onChange={onChange} agreement={agreement} />,
            validationFn: () => AgreementStep1Validation.validateAgreementForm(agreement),
            onNext: () => {
                if(!agreement.id) {
                    dispatch(createAgreement(agreement));
                }
                else {
                    dispatch(updateAgreement(agreement));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddAgreementStep2 onChangeVersion={onChangeVersion} agreement={agreement} />,
            validationFn: () => AgreementStep2Validation.validateAgreementVersionForm(agreement.version),
            onNext: () => {
                if(!agreement.version?.id) {
                    dispatch(createAgreementVersion(agreement));
                }
                else {
                    dispatch(updateAgreementVersion(agreement.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <AddAgreementVersionStep2 agreementId={selectedAgreementId} onChange={setSelectedAgreementId} />,
            description: messages.wouldYouLikeToCloneVersion,
            isOptional: !selectedAgreementId
        } as GenericStep,
        {
            id: 4,
            content: <AddAgreementVersionStep3 agreementId={selectedAgreementId} selectedAgreementVersionId={selectedAgreementVersionId} onChange={setSelectedAgreementVersionId}/>,
            validationFn: () => AgreementStep3Validation.validateAddAgreementVersionForm(selectedAgreementVersionId),
            description: messages.chooseVersion,
            // onNext: (ref: GenericRef<AddAgreementVersionStep3State>) => {
            //     const data = ref.current.getData();
            //     const { selectedAgreementVersionId } = data;

            //     setSelectedAgreementVersionId(selectedAgreementVersionId);
            // },
            excludeIf: !selectedAgreementId
        } as GenericStep,
        {
            id: 6,
            content: <AddAgreementVersionStep4 onChange={onChangeVersion} agreementVersion={agreement.version}/>,
            onNext: () => {

                dispatch(cloneAgreementVersion({ toVersionId: ensure(propsAgreement.version?.id),
                    fromVersionId: ensure(selectedAgreementVersionId),
                    percentAdjustmentSpecialPrices: agreement?.version?.percentAdjustmentSpecialPrices ?? 0,
                    percentAdjustmentTransitDiscount: agreement?.version?.percentAdjustmentTransitDiscount ?? 0
                }));
            },
            excludeIf: !selectedAgreementId
        } as GenericStep,
        {
            id: 5,
            content: <AddAgreementStep3 onChangeVersion={onChangeVersion} agreement={agreement} />,
            onNext: () => {
                dispatch(updateAgreementVersionStatus(ensure(agreement.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={agreement.version?.id ? `/${UrlConstants.AGREEMENTS}/${agreement.id}/${agreement.version.id}` : `/${UrlConstants.AGREEMENTS}/${agreement.id}/latest`}
            additionalDetails={<StepperAdditionlInformation agreement={agreement}/>}
        />
    );
}
  