import React from 'react';
import { Autocomplete, createFilterOptions, InputAdornment, TextField, Tooltip } from '@mui/material';
import { MessageDescriptor, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { ensure } from '../../../utils/arrayUtils';
import { HelpOutline } from '@mui/icons-material';

interface IGenericAutocompleteProps<T> {
    /**
     * Autocomplete options. Array of Type T
     */
    options: Array<T>;
    /**
     * Label used in default comparison - default compareFn
     */
    label: string;
    /**
     * Selected value. The value should be included in the options (either found through a function, either direct value)
     */
    value?: any;
    /**
     * OnChange function used for single selection
     */
    onChange: ((x: T | null) => void);
    /**
     * OnChange function used for multiple selection
     */
    onMultipleChange: ((x: T[]) => void);
    /**
     * If true, it shows the spinner
     */
    isLoading: boolean;
    /**
     * Placeholder object used by react-intl to display the placeholder
     */
    placeholder: MessageDescriptor | undefined;
    /**
     * Function used for finding the value in the options array. By default, it matches options by 'id' property
     */
    compareFn: (o: T, value?: any) => boolean;
    /**
     * Function used for diplaying the selected data. By default it renders option[label] where label is the props key
     */
    getOptionLabel: (option: T, label: string) => string;
    /**
     *  Boolean mentioning whether it should have multiselection or not. If true, it should have onMultipleChange property defined.
     */
    multiple?: boolean;
    /**
     *  Boolean mentioning whether the auto complete should be disabled or not.
     */
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    helperText?: MessageDescriptor;
}

const defaultProps: IGenericAutocompleteProps<any> = {
    label: 'name',
    options: [],
    value: undefined,
    onChange: () => { return undefined; },
    onMultipleChange: () => { return undefined; },
    isLoading: false,
    placeholder: undefined,
    compareFn: (o: any, value: any) => o.id === value?.id,
    getOptionLabel: (option: any, labelOption: string) => option[labelOption]?.toString() || '',
    multiple: false,
    disabled: false
};

const GenericAutocomplete = <T,>(props: IGenericAutocompleteProps<T>): JSX.Element => {
    const { options, label, value, onChange, isLoading, placeholder, compareFn, getOptionLabel, multiple, onMultipleChange, disabled, required, error, helperText } = props;
    const { formatMessage } = useIntl();
    const _getValue = () => {
        if(multiple) {
            return (value as T[])?.map(v => ensure(options.find(o => compareFn(o, v)))) || [];
        }
        else {
            return options.find(o => compareFn(o, value)) || null;
        }
    };
    const _onChange = (event: any, newValue: T | null | T[]) => {
        if(multiple) {
            onMultipleChange(newValue as (T[]));
        }
        else {
            onChange(newValue as (T | null));
        }
    };
    const tooltipText = helperText ? formatMessage(helperText) : '';

    //enable this for helptext near the label

    // const getLabel = () : React.ReactNode => {
    //     const placeholderMessage = placeholder ? formatMessage(placeholder) : '';
    //     const helperTextMessage = helperText ? formatMessage(helperText) : '';

    //     if(helperTextMessage) {
    //         return `${placeholderMessage} (${helperTextMessage})`;
    //     }

    //     return placeholderMessage;
    // };
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: T) => getOptionLabel(option, label)
    });

    return <Autocomplete
        options={options}
        autoHighlight
        value={_getValue()}
        getOptionLabel={(option: T) => getOptionLabel(option, label)}
        onChange={_onChange}
        loading={isLoading}
        multiple={multiple}
        disabled={disabled}
        sx={{
            ' .MuiAutocomplete-popupIndicator': {
                position: 'absolute',
                right: tooltipText ? 30 : 0
            },
            ' .MuiAutocomplete-clearIndicator': {
                position: 'absolute',
                right: tooltipText ? 60 : 30
            },
            ' .MuiAutocomplete-endAdornment': {
                position: 'absolute',
                top: '10%'
            }
        }}
        filterOptions={filterOptions}
        renderInput={(params) => <>
            <TextField {...params} variant="standard" label={placeholder ? formatMessage(placeholder) : ''}
                error={error}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} sx={{
                                    position: 'absolute',
                                    right: tooltipText ? 60 : 0
                                }} /> : null}
                                {tooltipText &&
                                    <Tooltip title={tooltipText} style={{
                                        position: 'absolute',
                                        right: 0
                                    }}>
                                        <HelpOutline />
                                    </Tooltip>
                                }
                                {params.InputProps.endAdornment}
                            </>
                        </InputAdornment>
                    )
                }} required={required} />
        </>}
    />;
};

GenericAutocomplete.defaultProps = defaultProps;
export default GenericAutocomplete;