import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import { FormType } from '../../constants/constants';
import { createPricelistVersionProduct, fetchPricelistVersionProductById, getPricelistVersionProduct, isCreatingPriceListVersionProduct, isLoadingPriceListVersionProduct,
    isUpdatingPriceListVersionProduct, updatePricelistVersionProduct } from '../../reducers/pricelistVersionProductReducer';
import AddPricelistVersionProductStep1 from './steps/addPricelistVersionProductStep1';
import AddPricelistVersionProductStep2 from './steps/addPricelistVersionProductStep2';
import AddPricelistVersionProductStep3 from './steps/addPricelistVersionProductStep3';
import AddPricelistVersionProductStep4 from './steps/addPricelistVersionProductStep4';
import PricelistVersionProduct from '../../interfaces/output/pricelistVersionProduct';
import { useIntl } from 'react-intl';
import PricelistVersionProductStep2Validation from '../pricelists/validation/pricelistVersionProductStep2Validation';
import PricelistVersionProductStep1Validation from '../pricelists/validation/pricelistVersionProductStep1Validation';
import PricelistVersionProductStep4Validation from '../pricelists/validation/pricelistVersionProductStep4Validation';
import StepperAdditionalInformation from '../common/widgets/stepperAdditionalInformation';

interface IAddPricelistVersionProductFormProps {
    priceListId?: number;
    priceListVersionId?: number;
    productId?: number;
    id?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    step1: LanguageUtils.createMessage('STEP_SELECT_PRICE_LIST'),
    step2: LanguageUtils.createMessage('STEP_SELECT_PRODUCT'),
    step3: LanguageUtils.createMessage('STEP_SELECT_PRICE_LIST_VERSION'),
    step4: LanguageUtils.createMessage('STEP_ENTER_BASE_PRICE')
};

export default function AddPricelistVersionProductForm(props: IAddPricelistVersionProductFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { priceListId, productId, steps, id, priceListVersionId, type } = props;
    const [pricelistVersionProduct, setPricelistVersionProduct] = React.useState<PricelistVersionProduct>({ product: { id: productId },
        pricelist: { id: priceListId,
            version: { id: priceListVersionId } } } as PricelistVersionProduct);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsisCreatingPriceListVersionProduct = useSelector(isCreatingPriceListVersionProduct);
    const prevIsCreatingPriceListVersionProduct = WorkflowUtils.usePrevious<boolean>(propsisCreatingPriceListVersionProduct);
    const propsIsUpdatingPriceListVersionProduct = useSelector(isUpdatingPriceListVersionProduct);
    const prevIsUpdatingPriceListVersionProduct = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriceListVersionProduct);
    const propsIsLoadingPriceListVersionProduct = useSelector(isLoadingPriceListVersionProduct);
    const prevIsLoadingPriceListVersionProduct = WorkflowUtils.usePrevious<boolean>(propsIsLoadingPriceListVersionProduct);
    const propsPricelistVersionProduct = useSelector(getPricelistVersionProduct);
    // eslint-disable-next-line no-console
    console.log(pricelistVersionProduct);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 4:
                if(prevIsUpdatingPriceListVersionProduct && !propsIsUpdatingPriceListVersionProduct || prevIsCreatingPriceListVersionProduct && !propsisCreatingPriceListVersionProduct) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsisCreatingPriceListVersionProduct, propsIsUpdatingPriceListVersionProduct]);
    
    useEffect(() => {
        if(id) {
            dispatch(fetchPricelistVersionProductById(id));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(!propsIsLoadingPriceListVersionProduct && prevIsLoadingPriceListVersionProduct) {
            setPricelistVersionProduct(propsPricelistVersionProduct);
        }
    }, [propsIsLoadingPriceListVersionProduct]);

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: formatMessage(messages.step1),
            xl: true,
            validationFn: () => PricelistVersionProductStep1Validation.validatePricelistVersionPriceListForm(pricelistVersionProduct.pricelist?.id),
            content: <AddPricelistVersionProductStep1 onChange={setPricelistVersionProduct} pricelistVersionProduct={pricelistVersionProduct} productId={productId}/>
        } as GenericStep,
        {
            id: 2,
            name: formatMessage(messages.step2),
            xl: true,
            validationFn: () => PricelistVersionProductStep2Validation.validatePricelistVersionProductForm(pricelistVersionProduct.product?.id),
            content: <AddPricelistVersionProductStep2 onChange={setPricelistVersionProduct} pricelistVersionProduct={pricelistVersionProduct} priceListId={priceListId}
                priceListVersionId={priceListVersionId} />
        } as GenericStep,
        {
            id: 3,
            name: formatMessage(messages.step3),
            xl: true,
            content: <AddPricelistVersionProductStep3 onChange={setPricelistVersionProduct} pricelistVersionProduct={pricelistVersionProduct} />
        } as GenericStep,
        {
            id: 4,
            name: formatMessage(messages.step4),
            validationFn: () => PricelistVersionProductStep4Validation.validatePricelistVersionProductForm(pricelistVersionProduct),
            content: <AddPricelistVersionProductStep4 onChange={setPricelistVersionProduct} pricelistVersionProduct={pricelistVersionProduct} />,
            onNext: () => {
                if(!pricelistVersionProduct.id) {
                    dispatch(createPricelistVersionProduct(pricelistVersionProduct));
                }
                else {
                    dispatch(updatePricelistVersionProduct(pricelistVersionProduct));
                }
            } } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.EditPriceListVersionProductViaPriceList:
            case FormType.EditPriceListVersionProductViaProduct:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.Add:
                if(productId) {
                    return `/${UrlConstants.PRODUCTS}/${productId}/pricelists`;
                }
        
                return `/${UrlConstants.PRICE_LISTS}/${priceListId}/${priceListVersionId}/products`;

            case FormType.EditPriceListVersionProductViaPriceList:
                return `/${UrlConstants.PRICE_LISTS}/${propsPricelistVersionProduct.pricelist?.id}/${propsPricelistVersionProduct.pricelist?.version?.id}/products`;

            case FormType.EditPriceListVersionProductViaProduct:
                return `/${UrlConstants.PRODUCTS}/${propsPricelistVersionProduct.product?.id}/pricelists`;

            default:
                throw new Error('Form type required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
            additionalDetails={<StepperAdditionalInformation priceList={pricelistVersionProduct?.pricelist} productId={productId}/>}
        />
    );
}
  